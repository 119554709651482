var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _vm.error
        ? _c("ErrorAlert", {
            attrs: { dismissible: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [_vm._v(" " + _vm._s(_vm.error) + " ")]
                  },
                  proxy: true
                },
                {
                  key: "dismiss",
                  fn: function() {
                    return [_c("BIconXCircle", { attrs: { title: "Dismiss" } })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3783548109
            )
          })
        : _vm._e(),
      _c("MapController", {
        attrs: {
          "can-access-playback": "",
          "can-access-utilities": "",
          "parent-loading": _vm.loading || !_vm.processedMapToolData,
          "has-api-kills": true,
          "has-api-positions": false,
          "has-economy": true,
          "has-outcome": false,
          "has-plants": false,
          "has-reports": false,
          "has-wins": true,
          "has-vod": true,
          "is-scrim": true,
          "create-bookmark-api": _vm.createScrimNote,
          data: _vm.processedMapToolData,
          map: _vm.scrims && _vm.scrims[0] && _vm.scrims[0].map,
          notes: _vm.notesForMapTool,
          "note-tags": _vm.noteTags,
          team: _vm.selfTeam,
          "initial-state": _vm.initialState
        },
        on: {
          "update:initialState": function($event) {
            return _vm.$emit("update:initialState", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }