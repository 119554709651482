import calcTeamEco, { calcEcoAmount } from './calcTeamEco.js'

function calcScrimEco(team, roundNum, tabDetections) {
  if (!tabDetections) {
    return null
  }

  return calcTeamEco(calcEcoAmount(this.weaponsById, this.gearsById, team, tabDetections), roundNum, true)
}

export default calcScrimEco
