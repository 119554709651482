<template>
  <div class="main-container">
    <ErrorAlert dismissible v-if="error">
      <template #default>
        {{ error }}
      </template>
      <template #dismiss>
        <BIconXCircle title="Dismiss" />
      </template>
    </ErrorAlert>
    <MapController
      can-access-playback
      can-access-utilities
      :parent-loading="loading || !processedMapToolData"
      :has-api-kills="true"
      :has-api-positions="false"
      :has-economy="true"
      :has-outcome="false"
      :has-plants="false"
      :has-reports="false"
      :has-wins="true"
      :has-vod="true"
      :is-scrim="true"
      :create-bookmark-api="createScrimNote"
      :data="processedMapToolData"
      :map="scrims && scrims[0] && scrims[0].map"
      :notes="notesForMapTool"
      :note-tags="noteTags"
      :team="selfTeam"
      :initial-state="initialState"
      @update:initialState="$emit('update:initialState', $event)"
    />
  </div>
</template>

<script>
import { BIconXCircle } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { createScrimNote } from '@/api/notes.js'
import { createShortcut } from '@/api/shortcuts'
import ErrorAlert from '@/components/generic/ErrorAlert.vue'
import { pxNullable } from '@/components/Map/types'
import genRoundId from '@/components/Map/utils/genRoundId'
import { BOOKMARK_VERSION } from '@/constants'
import mapPage from '@/pages/mixins/mapPage'

import MapController from '../Map/MapController.vue'

export default {
  name: 'ScrimMapTool',
  mixins: [mapPage],
  components: {
    MapController,
    ErrorAlert,
    BIconXCircle,
  },
  props: {
    loading: Boolean,
    scrims: Array,
    mapData: pxNullable(px.object),
    notes: pxNullable(px.array),
    noteTags: pxNullable(px.array),
    initialState: pxNullable(px.string),
  },
  data: () => ({
    error: null,
  }),
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
      weaponsById: 'static/weaponsById',
    }),
    processedMapToolData() {
      return Object.freeze({
        ...this.mapData,
        matchesData: Object.fromEntries(Object.entries(this.mapData?.matches || {}).map(([id, match]) => [id, match])),
      })
    },
    selfTeam() {
      return this.scrims?.[0].teams?.find(team => team.is_self_team)?.id
    },
    notesForMapTool() {
      return Object.freeze(this.notes || [])
    },
  },
  methods: {
    async createScrimNote(bookmark) {
      const { matchId, text, tags, round_num, round_millis, ...extraState } = bookmark
      const shortcut = await createShortcut(extraState)

      const params = {
        matchId,
        text,
        tags,
        round_num,
        round_millis,
        extra_data: {
          shortcut: shortcut,
          version: BOOKMARK_VERSION,
        },
      }

      const newNote = Object.freeze({
        ...(await createScrimNote(params)),
        ...extraState,
        round_id: genRoundId(matchId, round_num),
        tags: tags.map(tagId => this.noteTags.find(tag => tagId === tag.id)),
      })

      this.notes.push(newNote)
      return newNote
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}
</style>
