const PLAYER_STATS = Object.freeze({
  kills: 0,
  deaths: 0,
  assists: 0,
  headshots: 0,
  head_shot_perc: 0,
  first_kills: 0,
  first_deaths: 0,
})

/**
 * Sums several player stats
 * @param {Partial<PLAYER_STATS>} stats
 * @returns {PLAYER_STATS}
 */
export const sumPlayerStats = (...stats) =>
  stats.reduce(
    (acc, stat) => {
      if (!stat) return acc
      Object.keys(PLAYER_STATS)
        .filter(key => key !== 'head_shot_perc')
        .forEach(key => {
          if (typeof stat[key] === 'number') {
            acc[key] += stat[key]
          } else if (stat[key] != null) {
            throw new Error(`Invalid stat ${key} of type ${typeof stat[key]} and value ${stat[key]}`)
          }
        })

      acc['head_shot_perc'] = acc['headshots'] / acc['kills']

      return acc
    },
    { ...PLAYER_STATS }
  )

/**
 * Computes common player stats from a killfeed.
 * @param {Array} roundKillfeed
 * * @param {Array} existing playerStats
 * @returns {Record<string, PLAYER_STATS>}
 */
const calcScrimPlayerStatsFromKillfeed = (roundKillfeed, playerStats = {}) => {
  if (playerStats == null) {
    playerStats = {}
  }
  const sortedKillfeed = [...(roundKillfeed || [])].sort((a, b) => a.round_time_millis - b.round_time_millis)

  sortedKillfeed.forEach(({ assisted_puuids, source_puuid, tactics, target_puuid }, index) => {
    if (source_puuid) {
      const stats = {
        kills: 1,
        headshots: tactics?.includes('headshot') ? 1 : 0,
        first_kills: index === 0 ? 1 : 0,
      }

      playerStats[source_puuid] = sumPlayerStats(playerStats[source_puuid], stats)
    }

    if (target_puuid) {
      const stats = {
        deaths: 1,
        first_deaths: index === 0 ? 1 : 0,
      }

      playerStats[target_puuid] = sumPlayerStats(playerStats[target_puuid], stats)
    }

    assisted_puuids?.forEach(assisted_puuid => {
      if (assisted_puuid) {
        playerStats[assisted_puuid] = sumPlayerStats(playerStats[assisted_puuid], {
          assists: 1,
        })
      }
    })
  })

  return playerStats
}

export default calcScrimPlayerStatsFromKillfeed
