import calcScrimPlayerStatsFromKillfeed from './calcScrimPlayerStatsFromKillfeed.js'
import { calcPlayerLoadout } from './calcTeamEco.js'

function extractScrimInterfaceDetectionStats(store, roundData, player) {
  if (!roundData) {
    return {}
  }

  const loadout = roundData.tabtable_detections_expand?.loadouts?.find(tabPlayer => tabPlayer.puuid === player.puuid)
  return {
    ...calcScrimPlayerStatsFromKillfeed(roundData.killfeed_detections_expand?.events)[player.puuid],
    loadout_value:
      calcPlayerLoadout(store.getters['static/weaponsById'], store.getters['static/gearsById'], loadout) || 'n/a',
    weapon_id: loadout?.weapon_id,
    weapon_name: loadout?.weapon_id ? store.getters['static/getWeaponById'](loadout.weapon_id)?.name : '',
    armor_id: loadout?.gear_id,
    armor_name: loadout?.gear_id ? store.getters['static/getGearById'](loadout.gear_id)?.name : '',
    armor_url: loadout?.gear_id ? store.getters['static/getGearById'](loadout.gear_id)?.display_icon_url : '',
  }
}

export default extractScrimInterfaceDetectionStats
