<template>
  <ApiLoadingController class="map-wrapper" :fetch="fetch" :params="params">
    <template #default="{ data }">
      <ScrimMapTool v-bind="data" :initial-state="initialState" @update:initialState="updateInitialState" />
    </template>

    <template #loading>
      <ScrimMapTool
        v-bind="loadingData"
        loading
        :initial-state="initialState"
        @update:initialState="updateInitialState"
      />
    </template>
  </ApiLoadingController>
</template>

<script>
/* eslint-disable no-unused-vars */
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { getScrim, getScrimAdvancedRoundInfo, getScrimReplayInfo } from '@/api/scrims'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import { pxNullable } from '@/components/Map/types'
import genMatchPlayerId from '@/components/Map/utils/genMatchPlayerId'
import genRoundPlayerId from '@/components/Map/utils/genRoundPlayerId'
import ScrimMapTool from '@/components/scrim/ScrimMapTool.vue'
import { SCRIM_MAX_ROUND_TIME_IN_SECONDS } from '@/constants.js'
import mixpanel from '@/mixpanel.js'
import mapPage from '@/pages/mixins/mapPage'
import abbrv from '@/utils/abbrv'
import calcScrimPlayerStatsFromKillfeed from '@/utils/calcScrimPlayerStatsFromKillfeed.js'
import calcTeamEco, { calcEcoAmount, calcPlayerLoadout } from '@/utils/calcTeamEco.js'
// import checkInconsistentScrimInterfaceDetectionsData from '@/utils/checkScrimInterfaceDetectionData'
import { prepareScrimData } from '@/utils/prepareScrimData'
import reportException from '@/utils/reportException'

import genRoundId from '../../components/Map/utils/genRoundId.js'
import calcTeamRole from '../../utils/calcTeamRole.js'
import prepareMinimapDetectedData from '../../utils/prepareMinimapDetectedData.js'
import prepareScrimDataForMapToolProcess from '../../utils/prepareScrimDataForMapToolProcess.js'
import processMapToolData from '../../utils/processMapToolData.js'

export default {
  name: 'ScrimMapToolPage',
  mixins: [mapPage],
  components: {
    ScrimMapTool,
    ApiLoadingController,
  },
  props: {
    scrims: Array,
    s: pxNullable(px.string).def(null),
  },
  data: () => ({
    loadingData: null,
  }),
  computed: {
    ...mapGetters({
      agentsList: 'static/agentsList',
      getMapById: 'static/getMapById',
      getWeaponById: 'static/getWeaponById',
      getGearById: 'static/getGearById',
      weaponsList: 'static/weaponsList',
      gearsList: 'static/gearsList',
      weaponsById: 'static/weaponsById',
      gearsById: 'static/gearsById',
      agentsById: 'static/agentsById',
    }),
    params() {
      return this.scrims
    },
  },
  methods: {
    async fetch(scrimIds, options) {
      let failedApiCalls = 0

      try {
        const scrimsInfo = await Promise.all(
          scrimIds.map(scrimId => Promise.all([getScrim(scrimId, options), getScrimReplayInfo(scrimId, options)]))
        )
        const mapInfo = this.getMapById(scrimsInfo[0][0].map)

        const { scrims: loadingScrims, ...loadingProcessMapToolDataParams } = prepareScrimDataForMapToolProcess.call(
          this,
          {
            mapInfo,
            scrimsData: Object.fromEntries(scrimsInfo.map(([scrimData]) => [scrimData.id, scrimData])),
            replayInfos: Object.fromEntries(scrimsInfo.map(([scrimData, replayInfo]) => [scrimData.id, replayInfo])),
          }
        )
        this.loadingData = {
          scrims: loadingScrims,
          mapData: await processMapToolData({
            staticData: {
              agentsData: this.agentsList,
              weaponsData: this.weaponsList,
              gearsData: this.gearsList,
            },
            ...loadingProcessMapToolDataParams,
            hasKillLocations: false,
            reportMissingLocations: false,
          }),
        }

        const roundData = await Promise.all(
          scrimsInfo.map(([{ id: scrim_id, rounds_played }]) =>
            Promise.all(
              Array.from({ length: rounds_played }, (_, i) => i).map(async round_num => {
                try {
                  return {
                    scrim_id,
                    ...(await getScrimAdvancedRoundInfo(scrim_id, round_num, options)),
                  }
                } catch (e) {
                  failedApiCalls++
                  if (!/^Network error 40[3-4]/.test(e.message)) {
                    reportException(e)
                  }
                }
              })
            )
          )
        )

        const { scrims, ...processMapToolDataParams } = prepareScrimDataForMapToolProcess.call(this, {
          mapInfo,
          scrimsData: Object.fromEntries(scrimsInfo.map(([scrimData]) => [scrimData.id, scrimData])),
          replaysInfo: Object.fromEntries(scrimsInfo.map(([scrimData, replayInfo]) => [scrimData.id, replayInfo])),
          roundsData: Object.fromEntries(scrimsInfo.map(([scrimData], idx) => [scrimData.id, roundData[idx]])),
        })

        const result = {
          scrims: Object.freeze(scrims),
          mapData: {
            ...(await processMapToolData({
              staticData: { agentsData: this.agentsList, weaponsData: this.weaponsList, gearsData: this.gearsList },
              ...processMapToolDataParams,
              hasKillLocations: true,
              reportMissingLocations: false,
            })),
          },
          incompleteMlData: failedApiCalls > 0,
        }

        // result.mapData = Object.freeze({
        //   ...result.mapData,
        //   roundStats: Array.from({ length: scrim.rounds_played }, (_, round_num) => {
        //     return scrim.teams
        //       .map(team => ({
        //         id: team.id,
        //         name: team.name,
        //         grid: team.team_side,
        //         score: 0,
        //         players: team.players.map(player => ({
        //           puuid: player.puuid,
        //           name: player.name,
        //           agent_id: player.agent,
        //           agent_name: this.agentsById[player.agent]?.name,
        //           agent_thumbnail: this.agentsById[player.agent]?.name
        //             .replace('/', '') // "KAY/O" case
        //             .toLowerCase(),
        //           ultimates: {
        //             count:
        //               result.mapData.roundPlayers[
        //                 genRoundPlayerId(genRoundId(this.scrimId, round_num), player.id || player.puuid)
        //               ]?.ultimate_orbs || 'n/a',
        //             max: result.mapData.matchPlayers[genMatchPlayerId(this.scrimId, player.id || player.puuid)]
        //               ?.max_ultimate_orbs,
        //           },
        //           ...this.extractInterfaceDetectionStats(scrim.roundData[round_num], player),
        //         })),
        //       }))
        //       .sort(a => (a.grid === scrim.pov_grid ? -1 : 1))
        //   }),
        // })

        // prepare Notes and tags
        // result.noteTags = await getNoteTags()
        // result.notes = await getScrimNotes(scrim.id)
        // result.notes.forEach(note => {
        //   note.round_id = genRoundId(scrim.id, note.round_num)
        //   note.tags = note.tags.map(tagId => result.noteTags.find(tag => tagId === tag.id))
        // })
        //
        scrims.forEach(scrim => {
          mixpanel.track_scrim_load_data({
            type: 'scrim',
            incompleteMlData: failedApiCalls > 0,
            scrimId: scrim.id,
            odmVersion: scrim.vod?.odm_version,
            failedApiCalls,
            vodState: scrim.vod?.state,
            detectedEconomyPercent:
              (100 *
                Object.values(result.mapData.roundTeams).reduce((acc, team) => acc + (team.eco != null ? 1 : 0), 0)) /
              scrim.rounds_played /
              2,
            detectedMaxOrbsPerPlayerPercent:
              (100 *
                Object.values(result.mapData.matchPlayers).reduce(
                  (acc, player) => acc + (player.max_ultimate_orbs > 1 ? 1 : 0),
                  0
                )) /
              10,
            detectedOrbsPerPlayerPercent:
              (100 *
                Object.values(result.mapData.roundPlayers).reduce(
                  (acc, player) => acc + (player.ultimate_orbs != null ? 1 : 0),
                  0
                )) /
              scrim.rounds_played /
              10,
            positionsPerRoundPerPlayer:
              Object.values(processMapToolDataParams.roundAdvancedPositionsData).reduce(
                (acc, list) => acc + list.length,
                0
              ) /
              scrim.rounds_played /
              10,
            smokesPerRound:
              Object.values(processMapToolDataParams.roundSmokesData).reduce((acc, list) => acc + list.length, 0) /
              scrim.rounds_played,
            utilitiesPerRound:
              Object.values(processMapToolDataParams.roundUtilitiesData).reduce((acc, list) => acc + list.length, 0) /
              scrim.rounds_played,
            wallsPerRound:
              Object.values(processMapToolDataParams.roundWallsData).reduce((acc, list) => acc + list.length, 0) /
              scrim.rounds_played,
            killsPerRound: result.mapData.kills.length / scrim.rounds_played,
            detectedWinPercent:
              (100 *
                Object.values(result.mapData.roundTeams).reduce((acc, team) => acc + (team.win != null ? 1 : 0), 0)) /
              scrim.rounds_played /
              2,
          })
        })
        return result
      } catch (e) {
        mixpanel.track_scrim_load_failure({
          type: 'scrim',
          scrimIds,
          error: e.message,
        })
        throw e
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper,
.map-wrapper2 {
  position: relative;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
</style>
