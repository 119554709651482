import genRoundId from '../components/Map/utils/genRoundId.js'
import { SCRIM_MAX_ROUND_TIME_IN_SECONDS } from '../constants.js'

import calcScrimEco from './calcScrimEco.js'
import calcScrimKillPositions from './calcScrimKillPositions.js'
import calcScrimOrbsData from './calcScrimOrbsData.js'
import calcScrimRoundWin from './calcScrimRoundWin.js'
import calcTeamRole from './calcTeamRole.js'
import extractScrimInterfaceDetectionStats from './extractScrimInterfaceDetectionStats.js'
import prepareMinimapDetectedData from './prepareMinimapDetectedData.js'
import { prepareScrimData } from './prepareScrimData.js'

function prepareScrimDataForMapToolProcess({ mapInfo, scrimsData, replaysInfo, roundsData }) {
  const scrims = Object.values(scrimsData).map(scrimData =>
    prepareScrimData.call(this, scrimData, roundsData?.[scrimData.id], replaysInfo?.[scrimData.id])
  )

  /**
   * @type {Record<String, API_PLAYER_POSITION[]>}
   */
  const roundAdvancedPositionsData = {}
  /**
   * @type {Record<String, API_SMOKE_EVENT[]>}
   */
  const roundSmokesData = {}
  /**
   * @type {Record<String, API_UTILITY_EVENT[]>}
   */
  const roundUtilitiesData = {}
  /**
   * @type {Record<String, API_WALL_EVENT[]>}
   */
  const roundWallsData = {}

  Object.values(roundsData || {}).forEach(scrimRoundData =>
    scrimRoundData?.forEach(round => {
      const roundId = round.id || genRoundId(round.scrim_id, round.round_num)
      const minimapDetections = [
        ...(round.minimap_detections_expand?.player_locations?.map(location => {
          return { ...location, type: 'agent' }
        }) || []),
        ...(round.minimap_detections_expand?.ability_locations?.map(location => {
          return { ...location, type: 'ability' }
        }) || []),
        ...(round.minimap_detections_expand?.smoke_locations?.map(location => {
          return { ...location, type: 'smoke' }
        }) || []),
        ...(round.minimap_detections_expand?.spike_locations?.map(location => {
          return { ...location, type: 'spike' }
        }) || []),
        ...(round.minimap_detections_expand?.wall_vectors?.map(location => {
          return { ...location, type: 'wall' }
        }) || []),
      ]
      const processedData = prepareMinimapDetectedData(minimapDetections, {
        mapRotatedDeg: mapInfo.rotate_deg,
      })

      if (!processedData) return

      roundAdvancedPositionsData[roundId] = processedData.positions
      roundSmokesData[roundId] = processedData.smokes
      roundUtilitiesData[roundId] = processedData.utilities
      roundWallsData[roundId] = processedData.walls
    })
  )

  return {
    scrims,
    matchesData: scrims.map(scrim => ({
      id: scrim.id,
      created: scrim.created,
      rounds: Object.values(scrim.roundData).map(round => {
        const roundId = round.id || genRoundId(round.scrim_id, round.round_num)
        return {
          round_num: round.round_num,
          round_length_millis: round.round_length_millis || SCRIM_MAX_ROUND_TIME_IN_SECONDS * 1000,
          player_stats: scrim.participants_through.map(player => ({
            puuid: player.puuid,
            game_name: player.name,
            agent_id: player.agent,
            agent_name: this.agentsById[player.agent]?.name,
            agent_thumbnail: this.agentsById[player.agent]?.name
              .replace('/', '') // "KAY/O" case
              .toLowerCase(),
            team_side: player.team_side,
            ...extractScrimInterfaceDetectionStats(this.$store, scrim.roundData[round.round_num], player),
          })),
          teams: scrim.teams.map(team => ({
            id: team.id,
            abbr: team.abbr,
            name: team.name,
            grid: team.team_side,
            role: calcTeamRole(team.team_side, round.round_num),
            rounds_won: scrim.roundScores.find(score => score.round_num === round.round_num)?.[team.team_side],
            team_side: team.team_side,
            ...(round.tabtable_detections_expand?.loadout
              ? {
                  eco: calcScrimEco(team, round.round_num, round.tabtable_detections_expand?.loadout),
                }
              : {}),
            ...(scrim.roundScores
              ? {
                  win: calcScrimRoundWin(team, round.round_num, scrim, scrim.roundScores),
                }
              : {}),
          })),
          ...(round.killfeed_detections_expand?.events
            ? {
                kills: round.killfeed_detections_expand?.events.map(kill => {
                  return {
                    round_time_millis: kill.round_time_millis,
                    killer_puuid: kill.source_puuid,
                    victim_puuid: kill.target_puuid,
                    finishing_damage: {
                      damage_type: kill.weapon_id != null ? 'Weapon' : 'Ability',
                      damage_item: kill.weapon_id != null ? kill.weapon_id : 'Ultimate',
                    },
                    killer_location: calcScrimKillPositions(
                      roundAdvancedPositionsData[roundId],
                      kill.source_puuid,
                      kill.round_time_millis
                    ),
                    victim_location: calcScrimKillPositions(
                      roundAdvancedPositionsData[roundId],
                      kill.target_puuid,
                      kill.round_time_millis
                    ),
                  }
                }),
              }
            : {}),
        }
      }),
      replay: scrim.replay,
      advancedData: roundsData?.[scrim.id],
      teams: scrim.teams.map(team => ({
        id: team.id,
        is_self_team: team.is_self_team,
        abbr: team.abbr,
        grid: team.team_side,
        name: team.name,
        rounds_won: team.rounds_won,
        team_side: team.team_side,
        win: team.win,
        players: team.players.map(player => ({
          id: player.id,
          puuid: player.puuid,
          agent_id: player.agent,
          name: player.name,
          is_active: true,
        })),
      })),
    })),
    roundAdvancedPositionsData,
    roundSmokesData,
    roundUtilitiesData,
    roundWallsData,
    orbsData: calcScrimOrbsData(scrims),
    isScrim: true,
    mapId: mapInfo.id,
  }
}

export default prepareScrimDataForMapToolProcess
