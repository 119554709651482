var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    staticClass: "map-wrapper",
    attrs: { fetch: _vm.fetch, params: _vm.params },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              "ScrimMapTool",
              _vm._b(
                {
                  attrs: { "initial-state": _vm.initialState },
                  on: { "update:initialState": _vm.updateInitialState }
                },
                "ScrimMapTool",
                data,
                false
              )
            )
          ]
        }
      },
      {
        key: "loading",
        fn: function() {
          return [
            _c(
              "ScrimMapTool",
              _vm._b(
                {
                  attrs: { loading: "", "initial-state": _vm.initialState },
                  on: { "update:initialState": _vm.updateInitialState }
                },
                "ScrimMapTool",
                _vm.loadingData,
                false
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }