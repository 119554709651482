function calcScrimOrbsData(scrims) {
  const orbsData = {}
  scrims.forEach(scrim => {
    orbsData[scrim.id] = []
    if (scrim.roundData) {
      Object.values(scrim.roundData)?.forEach(roundData => {
        orbsData[scrim.id].push({
          round_num: roundData.round_num,
          abilities: [],
          ultimates: roundData.tabtable_detections_expand?.ultimate_counts.map(ultimate => {
            return {
              puuid: ultimate.puuid,
              count: ultimate.count,
              max: ultimate.max_count,
            }
          }),
        })
      })
    }
  })
  return orbsData
}

export default calcScrimOrbsData
